import React, { useEffect } from 'react';
import * as styles from './landing.module.scss';
import Header from '@components/molecules/Header';

import Intro from '@components/molecules/videoSections/Intro'
import Presentation from '@components/molecules/videoSections/Presentation';
import Reviews from '@components/molecules/landingSections/Reviews';
import Join from '@components/molecules/landingSections/Join';
import CTA from '@components/molecules/videoSections/CTA';
import Footer from '@components/molecules/Footer';
import { authService } from '../../services/authService';
import { setLoggedIn, setUserData } from '../../state/auth/actions';
import { connect } from 'react-redux';
import { isBrowser } from '../../utils/ssr';

const LandingLayout = ({ setIsLoggedIn, setUserData }) => {
	useEffect(() => {
		checkingAuthorization();
		init();
	}, []);

	const checkingAuthorization = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn && isBrowser) {
			window.location.href = `${window.location.protocol}//app.${window.location.hostname}`;
		}
	};

	const init = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn) {
			setIsLoggedIn(true);
			setUserData(profile.accountData);
		}
	};

	return (
		<div className={styles.landingLayout}>
			{/* <div className={styles.topMap}> */}
				<Header />
				<Intro />
				<Presentation />
			{/* </div> */}
			<div className={styles.bottomMap}>
				<Reviews />
				<CTA/>
				<Join />
			</div>
			<Footer />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setIsLoggedIn: (payload) => dispatch(setLoggedIn(payload)),
	setUserData: (payload) => dispatch(setUserData(payload)),
});

export default connect(null, mapDispatchToProps)(LandingLayout);
